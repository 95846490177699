import { useContext, useEffect, useRef, useState } from "react";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import "./Profile.scss";
import EyeIcon from "../../assets/visibility.svg";
import { useLocation, useNavigate } from "react-router-dom";
import SubjectExamListAccordion from "../../components/ExamList/SubjectExamListAccordion";
import ProfileHeader from "../../components/ProfileHeader/ProfileHeader";
import {
  requiredValidation,
  isValidNumber,
  requiredPhoneValidation,
  isValidEmail,
  requiredEmailValidation,
} from "../../utils/formutils";
import {
  jsonDecryptAES,
  jsonEncryptAES,
  shuffle,
  shuffleArray,
} from "../../utils/common";
import { adminPath } from "../../constants/api";
import { courseList } from "../../constants/constants";
import { Typeahead } from "react-bootstrap-typeahead";
import { SnackbarContext } from "../../contexts/SnackbarContext";
import { Form, InputGroup, Tab, Tabs } from "react-bootstrap";
import { toast } from "react-toastify";
import ExamNotifyProfile from "../../components/ExamNotifyProfile/ExamNotifyProfile";
import _ from "lodash";
import dayjs from "dayjs";
import authInterceptor from "../../utils/authInterceptor";
import SubjectExamList from "../../components/ExamList/SubjectExamList";
import ExamList from "../../components/ExamList/ExamList";
const Profile = () => {
  const navigate = useNavigate();
  const token: any = sessionStorage.getItem("token");
  const userData: any = token ? jwtDecode(token) : {};
  const [globalError, setglobalError] = useState({
    isError: false,
    errorMsg: "",
  });
  const [collegeList, setCollegeList] = useState([]);
  const [packageData, setPackageData] = useState<any>();
  const [examPopData, setExamPopData] = useState([]);
  const [PQData, setPQData] = useState<any>();
  const [selectedExam, setSelectedExam] = useState<any>();
  const [quizQuestionList, setQuizQuestionList] = useState();
  const [updateId, setupdateId] = useState();
  const [initial, setinitial] = useState();
  const [showPassword, setshowPassword] = useState(false);
  const { setToster } = useContext(SnackbarContext);
  const [examData, setExamData] = useState([]);
  const [globalexamData, setGlobalExamData] = useState([]);
  const [nameData, setNameData] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState<any>();
  const [selectedSubjectCode, setSelectedSubjectCode] = useState<any>();
  const [notifyExamPQID, setNotifyExamPQID] = useState<any>();
  const { state } = useLocation();
  const [semesterList, setSemesterList] = useState<any>();
  const [subjects, setSubjects] = useState<any>([]);
  const [moduleList, setModuleList] = useState<any>();
  const [type, setType] = useState<any>("Module");

  const [profileData, setprofileData] = useState<any>({
    type: { value: "", errormsg: "", showError: false },
    name: { value: "", errormsg: "", showError: false },
    dateofbirth: { value: "", errormsg: "", showError: false },
    email: { value: "", errormsg: "", showError: false },
    mobile: { value: "", errormsg: "", showError: false },
    password: { value: "", errormsg: "", showError: false },
    state: { value: "", errormsg: "", showError: false },
    course: { value: "", errormsg: "", showError: false },
    college: { value: "", errormsg: "", showError: false },
    semester: { value: "", errormsg: "", showError: false },
    qualification: { value: "", errormsg: "", showError: false },
    rn: { value: "", errormsg: "", showError: false },
    isPasswordChanged: { value: false, errormsg: "", showError: false },
  });
  const [examType, setExamType] = useState([]);
  const [examTypeValue, setExamTypeValue] = useState("All");

  const [activeTab, setActiveTab] = useState("profile");
  const ref = useRef<any>(null);
  const getpurchasedDataIDData = async () => {
    try {
      const response: any = await authInterceptor.get(
        `${adminPath}PQD/FE/IHSID/${userData?.user?.IHSID}`
      );
      const decryptAESData: any = jsonDecryptAES(response?.data?.list);
      setPackageData(decryptAESData[0]?.packageData);

      const examListArray: any = [];
      let collageExamIdList: any = [];
      decryptAESData.map((data: any) => {
        data?.examData.map((inndata: any) => {
          inndata.pqdID = data?._id;
          if (data.collageExamId) collageExamIdList.push(data.collageExamId);
        });
        examListArray.push(...data?.examData);
      });
      setExamData(_.cloneDeep(examListArray));
      setGlobalExamData(_.cloneDeep(examListArray));
      getProfileDetails(userData.user.IHSID, collageExamIdList);
      setPQData(decryptAESData[0]);
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  const handleSubjectFilter = (e: any) => {
    let filtered: any;
    const value = e.target.value;
    if (value && value.length > 0 && value !== "Select") {
      filtered = globalexamData.filter((data: any) => {
        return data.subject === value;
      });
      const selectedSubject = subjects.filter(
        (data: any) => data.subjectCode === value
      )[0].subjectTitle;
      setSelectedSubject(selectedSubject);
      setSelectedSubjectCode(value);
      setExamData(filtered);
    } else {
      setExamData(_.cloneDeep(globalexamData));
      setSelectedSubject(null);
      setSelectedSubjectCode("Select");
    }
  };
  const handleModuleFilter = (value: any) => {
    let filtered: any;
    if (value && value.length > 0) {
      filtered = examData.filter((data: any) => {
        return data.subject === value[0].subjectCode;
      });
      setSelectedSubject(value[0].subjectTitle);
      setExamData(filtered);
    } else {
      setExamData(_.cloneDeep(globalexamData));
      setSelectedSubject(null);
    }
  };
  const handleTypeChange = (e: any) => {
    let filtered: any;
    setExamTypeValue(e.target.value);
    if (e.target.value === "All") {
      setExamData(_.cloneDeep(globalexamData));
    } else {
      filtered = globalexamData.filter((data: any) => {
        return data.type === e.target.value;
      });
      setExamData(filtered);
    }
  };

  const getSemData = async () => {
    try {
      const response = await authInterceptor.get(`${adminPath}semester`);
      const selectBoxList = response?.data?.list.map((item: any) => ({
        title: `${item.semName}`,
        value: item.semCode,
      }));
      setSemesterList(selectBoxList);
    } catch (error: any) {}
  };
  const getSubjects = async () => {
    try {
      const response = await authInterceptor.get(
        `${adminPath}subject/FE/sem/${profileData.semester.value}`
      );
      const decryptAESData: any = jsonDecryptAES(response.data);
      const subjects = decryptAESData.map((obj: any) => {
        return { subjectTitle: obj.subjectTitle, subjectCode: obj.subjectCode };
      });
      setSubjects(subjects);
    } catch (error: any) {}
  };

  const takeNotifyTest = async (examData: any) => {
    const timeDuration = dayjs(examData.endTime).diff(dayjs(), "minute");
    examData.duration = timeDuration;

    let PQDObj: any = {
      orderID:
        new Date().getFullYear() +
        new Date().getMonth() +
        new Date().getDate() +
        new Date().getHours() +
        new Date().getMinutes() +
        new Date().getSeconds() +
        new Date().getUTCMilliseconds(),
      userType: userData.user.role,
      IHSID: userData.user.IHSID,
      purchasedDate: dayjs(),
      packageData: {},
      examData: examData,
      collageExamId: examData._id,
      StudentName: userData.user.name,
      userName: userData.user.name,
      collageName: examData.collageName,
      semester: examData.semester,
      subject: examData.subject,
      type: examData.type,
    };
    const encryptPQD = await jsonEncryptAES(PQDObj);
    const purchasedDataID = await authInterceptor.post(`${adminPath}PQD/FE/`, {
      data: encryptPQD,
    });
    setNotifyExamPQID(purchasedDataID.data?.id);
    setTimeout(() => {
      getQuestionList(examData, true);
    });
  };
  const getModuleQuestionListData = async (data: any) => {
    let finalData: any = [];
    try {
      const requests = data.levelList.map((level: any) => {
        let obj = {
          moduleCode: data.selectedModule,
          LevelCode: level.code,
          qustionCount: level.qustionCount,
        };
        return authInterceptor.post(`${adminPath}quiz/FE/Module/Quiz`, obj);
      });
      return axios.all(requests).then((responses) => {
        responses.forEach((resp: any) => {
          finalData.push(...resp.data.list);
        });
        return finalData;
      });
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  const getQuestionSpecalityList = async (selectedType: any) => {
    let finalData: any = []; 
    setType("Speciality");
    setSelectedExam(selectedType);
    try {
      const requests = selectedType.moduleTestList.map((data: any) => {
        return getModuleQuestionListData(data);
      });
      axios.all(requests).then(async (responses) => {
        responses.forEach((resp: any) => {
          finalData.push(...resp);
        });
        finalData.forEach(async (item: any) => {
          if (item?.optionArray?.length > 0) {
            item.optionArray = await shuffle(item.optionArray);
          }
          if (item?.optionMatch?.length > 0) {
            item.optionMatch = await shuffle(item.optionMatch);
          }
          if (item?.optionSqu?.length > 0) {
            item.optionSqu = await shuffle(item.optionSqu);
          }
        });
        const shuffledFinal: any = await shuffleArray(finalData);
        setQuizQuestionList(shuffledFinal);
      });
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  const getModuleQuestionList = async (selectedType: any) => {
    let finalData: any = [];
    setSelectedExam(selectedType);
    try {
      const requests = selectedType.levelList.map((level: any) => {
        let obj = {
          moduleCode: selectedType.moduleCode,
          LevelCode: level.code,
          qustionCount: level.qustionCount,
        };
        return authInterceptor.post(`${adminPath}quiz/FE/Module/Quiz`, obj);
      });
      axios.all(requests).then(async (responses) => {
        responses.forEach((resp: any) => {
          finalData.push(...resp.data.list);
        });
        finalData.forEach(async (item: any) => {
          if (item?.optionArray?.length > 0) {
            item.optionArray = await shuffle(item.optionArray);
          }
          if (item?.optionMatch?.length > 0) {
            item.optionMatch = await shuffle(item.optionMatch);
          }
          if (item?.optionSqu?.length > 0) {
            item.optionSqu = await shuffle(item.optionSqu);
          }
        });
        const shuffledFinal: any = await shuffleArray(finalData);
        setQuizQuestionList(shuffledFinal);
      });
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  const getQuestionData = async (selectedType: any) => {
    if (selectedType.type === "Speciality" || selectedType.type === "Mock") {
      getQuestionSpecalityList(selectedType);
    } else {
      getModuleQuestionList(selectedType);
    }
  };
  const getQuestionList = async (selectedType: any, isNotify?: any) => {
    let finalData: any = [];
    setSelectedExam(selectedType);
    if (!isNotify) {
      setNotifyExamPQID(selectedType?.pqdID);
    }
    try {
      const requests = selectedType.levelList.map((level: any) => {
        let obj = {
          unitCode: selectedType.selecteUnitList,
          subjectCode: selectedType.subject,
          LevelCode: level.code,
          qustionCount: level.qustionCount,
        };
        return authInterceptor.post(`${adminPath}quiz/FE/Quiz`, obj);
      });
      axios.all(requests).then(async (responses) => {
        responses.forEach((resp: any) => {
          const decryptAESData: any = jsonDecryptAES(resp.data.list);
          finalData.push(...decryptAESData);
        });
        finalData.forEach(async (item: any) => {
          if (item?.optionArray?.length > 0) {
            item.optionArray = await shuffle(item.optionArray);
          }
          if (item?.optionMatch?.length > 0) {
            item.optionMatch = await shuffle(item.optionMatch);
          }
          if (item?.optionSqu?.length > 0) {
            item.optionSqu = await shuffle(item.optionSqu);
          }
        });
        const shuffledFinal: any = await shuffleArray(finalData);
        setQuizQuestionList(shuffledFinal);
      });
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  const resetFilter = () => {
    ref.current?.clear();
    setExamData(_.cloneDeep(globalexamData));
    setExamTypeValue("All");
    setSelectedSubject(null);
    setSelectedSubjectCode("Select");
  };
  useEffect(() => {
    getpurchasedDataIDData();
  }, [state]);

  useEffect(() => {
    if (quizQuestionList) {
      try {
        // authInterceptor
        //   .post(`${adminPath}tempdata`, {
        //     questionData: {
        //       selectedExam: selectedExam?._id,
        //       pqdId: notifyExamPQID,
        //       noOfQuestions: [],
        //       duration: selectedExam?.duration,
        //       passPercent: selectedExam?.passPercent,
        //       totalMark: selectedExam?.totalQuestionMark,
        //       quizQuestionList: quizQuestionList,
        //     },
        //   })
        //   .then((response: any) => {
        //     navigate("/testPage", {
        //       state: { testId: response?.data?.id, title: "" },
        //     });
        //   });

          let obj: any = {
            selectedExam: selectedExam?._id,
            pqdId: PQData?._id,
            noOfQuestions: selectedExam?.noOfQuestions,
            duration: selectedExam?.duration,
            passPercent: selectedExam?.passPercent,
            totalMark:
              selectedExam?.type === "Module"
                ? selectedExam?.totalQuestionMark
                : selectedExam?.totalMark,
            quizQuestionList: quizQuestionList,
            type: type,
            examResult:
              type === "Module"
                ? selectedExam?.specData?.examResult
                : selectedExam?.examResult,
            isSpecialityPackage: true,
            examtype: selectedExam?.type,
            subName: packageData?.title,
          };
          if (type === "Module") {
            obj["moduleCode"] = selectedExam?.selectedModule;
          }
          authInterceptor
            .post(`${adminPath}tempdata`, {
              questionData: obj,
            })
            .then((response: any) => {
              navigate("/testPage", {
                state: { testId: response?.data?.id, title: packageData?.title },
              });
            });

      } catch (error: any) {
        if (error.response) {
        }
      }
    }
  }, [quizQuestionList]);
  const getCollegeData = async () => {
    try {
      const response: any = await authInterceptor.get(
        `${adminPath}collegeAdmin`
      );
      setCollegeList(response?.data?.list);
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  const getProfileDetails = async (id: any, collageExamIdList: any) => {
    try {
      const response: any = await authInterceptor.get(
        `${adminPath}FElanding/IHSID/${id}`
      );
      const inputs = response?.data;
      await getExamList(inputs.college, inputs.semester, collageExamIdList);

      let obj: any = {};
      obj.name = {
        value: inputs.name,
        errormsg: "",
        showError: false,
      };
      obj.type = {
        value: inputs.type,
        errormsg: "",
        showError: false,
      };
      obj.dateofbirth = {
        value: inputs.dateofbirth,
        errormsg: "",
        showError: false,
      };
      obj.email = {
        value: inputs.email,
        errormsg: "",
        showError: false,
      };
      obj.mobile = {
        value: inputs.mobile,
        errormsg: "",
        showError: false,
      };
      obj.password = {
        value: inputs.password,
        errormsg: "",
        showError: false,
      };
      obj.state = {
        value: inputs.state,
        errormsg: "",
        showError: false,
      };
      obj.course = {
        value: inputs.course,
        errormsg: "",
        showError: false,
      };
      obj.college = {
        value: inputs.college,
        errormsg: "",
        showError: false,
      };
      obj.semester = {
        value: inputs.semester,
        errormsg: "",
        showError: false,
      };
      obj.qualification = {
        value: inputs.qualification,
        errormsg: "",
        showError: false,
      };
      obj.rn = {
        value: inputs.rn,
        errormsg: "",
        showError: false,
      };
      setprofileData({
        ...profileData,
        ...obj,
      });
      setupdateId(inputs._id);
      const intials = inputs.name.charAt(0);
      setinitial(intials);
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  const getExamType = async () => {
    try {
      const response = await authInterceptor.get(`${adminPath}common/TestType`);
      setExamType(response?.data?.list[0]?.testTypeArray);
    } catch (error: any) {}
  };
  useEffect(() => {
    getExamType();
    getCollegeData();
    getSemData();
  }, []);
  useEffect(() => {
    if (profileData && profileData.semester.value != "") {
      getSubjects();
    }
  }, [profileData]);

  const handleValidation = () => {
    let formIsValid = true;
    let obj: any = {};
    if (!profileData.name.value) {
      formIsValid = false;
      obj.name = requiredValidation(profileData.name.value);
    }
    if (!profileData.dateofbirth.value) {
      formIsValid = false;
      obj.dateofbirth = requiredValidation(profileData.dateofbirth.value);
    }
    if (!profileData.mobile.value) {
      formIsValid = false;
      obj.mobile = requiredValidation(profileData.mobile.value);
    } else if (isValidNumber(profileData.mobile.value)) {
      formIsValid = false;
      obj.mobile = requiredPhoneValidation(profileData.mobile.value);
    }
    if (!profileData.email.value) {
      formIsValid = false;
      obj.email = requiredValidation(profileData.email.value);
    } else if (isValidEmail(profileData.email.value)) {
      formIsValid = false;
      obj.email = requiredEmailValidation(profileData.email.value);
    }
    if (!profileData.state.value) {
      formIsValid = false;
      obj.state = requiredValidation(profileData.state.value);
    }
    if (!profileData.password.value) {
      formIsValid = false;
      obj.password = requiredValidation(profileData.password.value);
    }
    if (
      !profileData.college.value &&
      profileData.type.value === "NursingStudent"
    ) {
      formIsValid = false;
      obj.college = requiredValidation(profileData.college.value);
    }
    if (
      !profileData.course.value &&
      profileData.type.value === "NursingStudent"
    ) {
      formIsValid = false;
      obj.course = requiredValidation(profileData.course.value);
    }
    if (
      !profileData.semester.value &&
      profileData.type.value === "NursingStudent"
    ) {
      formIsValid = false;
      obj.semester = requiredValidation(profileData.semester.value);
    }
    if (
      !profileData.qualification.value &&
      profileData.type.value === "Nurse"
    ) {
      formIsValid = false;
      obj.qualification = requiredValidation(profileData.qualification.value);
    }
    if (!profileData.rn.value && profileData.type.value === "Nurse") {
      formIsValid = false;
      obj.rn = requiredValidation(profileData.rn.value);
    }
    const newData = { ...profileData, ...obj };
    setprofileData(newData);
    return formIsValid;
  };

  const handleUpdate = async (event: any) => {
    event.preventDefault();
    if (handleValidation()) {
      const d = new Date();
      let year = d.getFullYear();

      let payload: any = {
        IHSID: userData.user.IHSID,
        type: profileData.type.value,
        name: profileData.name.value,
        dateofbirth: profileData.dateofbirth.value,
        email: profileData.email.value,
        mobile: profileData.mobile.value,
        state: profileData.state.value,
        password: profileData.password.value,
        isPasswordChanged: profileData.isPasswordChanged.value,
      };
      if (profileData.type.value === "NursingStudent") {
        payload["course"] = profileData.course.value;
        payload["college"] =
          profileData.college.value.length > 0
            ? profileData.college.value[0].collegeName
            : "";
        payload["semester"] = profileData.semester.value;
      } else {
        payload["qualification"] = profileData.qualification.value;
        payload["rn"] = profileData.rn.value;
      }
      try {
        let apiResponse;
        if (profileData.type.value === "NursingStudent") {
          apiResponse = await authInterceptor.patch(
            `${adminPath}FElanding/userdetails/${updateId}`,
            payload
          );
          setToster({
            message: apiResponse?.data?.msg,
            type: "success",
          });
        } else {
          apiResponse = await authInterceptor.patch(
            `${adminPath}FElanding/userdetails/${updateId}`,
            payload
          );
          setToster({
            message: apiResponse?.data?.msg,
            type: "success",
          });
        }
        toast.success("Updated Successfully", {
          position: "top-center",
        });
      } catch (error: any) {
        const { data } = error.response;
        setglobalError({ isError: true, errorMsg: data.msg });
      }
    }
  };

  const handleChange = (e: any) => {
    console.log("event", e);
    if (e.target.name === "password") {
      setprofileData({
        ...profileData,
        ["password"]: {
          value: e.target.value,
          errormsg: "",
          showError: false,
        },
        ["isPasswordChanged"]: {
          value: true,
          errormsg: "",
          showError: false,
        },
      });
    } else {
      setprofileData({
        ...profileData,
        [e.target.name]: {
          value: e.target.value,
          errormsg: "",
          showError: false,
        },
      });
    }
  };

  const getExamList = async (
    college: any,
    semester: any,
    collageExamIdList: any
  ) => {
    try {
      const apiResponse = await authInterceptor.get(
        `${adminPath}scheduleExam/FE/${college}/${semester}`
      );
      const decryptAESData = await jsonDecryptAES(apiResponse.data.list);
      const filteredData: any = decryptAESData.filter((data: any) => {
        const isEnddateBefore = dayjs().isBefore(dayjs(data?.endTime));
        return !collageExamIdList.includes(data?._id) && isEnddateBefore;
      });
      setExamPopData(filteredData);
      console.log(decryptAESData);
    } catch (error: any) {
      console.log("error", error);
    }
  };

  return (
    <div className="profileContainer">
      <ProfileHeader
        initial={initial}
        profileData={profileData}
        userData={userData}
      />
      <div className="container">
        <Tabs
          defaultActiveKey="profile"
          className="im-tabs"
          activeKey={activeTab}
          onSelect={(k: any) => setActiveTab(k)}
        >
          <Tab eventKey="profile" title="Profile">
            <div className="profileInformation">
              <h2 className="profileText-title">Profile</h2>
              <div className="formWrapper">
                <div className="col-6 profile-input-box">
                  <label className="form-label profile-label">Name</label>
                  <input
                    type="text"
                    placeholder="Enter your name"
                    name="name"
                    disabled
                    value={profileData.name.value}
                    onChange={handleChange}
                    className="form-control profile-input"
                  />
                  {profileData.name.showError && (
                    <span className="profile-input-error">
                      {profileData.name.errormsg}
                    </span>
                  )}
                </div>
                <div className="col-6 profile-input-box">
                  <label className="form-label profile-label">
                    Date of Birth
                  </label>
                  <input
                    type="date"
                    placeholder="Enter your Date of Birth"
                    name="dateofbirth"
                    value={profileData.dateofbirth.value}
                    onChange={handleChange}
                    className="form-control profile-input"
                    // max={minDate}
                  />
                  {profileData.dateofbirth.showError && (
                    <span className="profile-input-error">
                      {profileData.dateofbirth.errormsg}
                    </span>
                  )}
                </div>
                <div className="col-6 profile-input-box">
                  <label className="form-label profile-label">Email</label>
                  <input
                    type="text"
                    placeholder="Enter your email"
                    name="email"
                    disabled
                    value={profileData.email.value}
                    onChange={handleChange}
                    className="form-control profile-input"
                  />
                  {profileData.email.showError && (
                    <span className="profile-input-error">
                      {profileData.email.errormsg}
                    </span>
                  )}
                </div>
                <div className="col-6 profile-input-box">
                  <label className="form-label profile-label">Mobile</label>
                  <input
                    type="text"
                    placeholder="Enter your mobile"
                    name="mobile"
                    value={profileData.mobile.value}
                    onChange={handleChange}
                    className="form-control profile-input"
                  />
                  {profileData.mobile.showError && (
                    <span className="profile-input-error">
                      {profileData.mobile.errormsg}
                    </span>
                  )}
                </div>
                <div className="col-6 profile-input-box">
                  <label className="form-label profile-label">Password</label>
                  <InputGroup>
                    <Form.Control
                      className="form-control profile-input"
                      type={showPassword ? "text" : "password"}
                      value={profileData.password.value}
                      onChange={handleChange}
                      name="password"
                    />
                    <button
                      className="eye-btn"
                      onClick={() => setshowPassword(!showPassword)}
                    >
                      <img src={EyeIcon} alt="back-btn" />
                    </button>
                  </InputGroup>
                  {profileData.password.showError && (
                    <span className="profile-input-error">
                      {profileData.password.errormsg}
                    </span>
                  )}
                </div>
                <div className="col-6 profile-input-box">
                  <label className="form-label profile-label">State</label>
                  <input
                    type="text"
                    placeholder="Enter your state"
                    name="state"
                    value={profileData.state.value}
                    onChange={handleChange}
                    className="form-control profile-input"
                  />
                  {profileData.state.showError && (
                    <span className="profile-input-error">
                      {profileData.state.errormsg}
                    </span>
                  )}
                </div>
                {profileData.type.value === "Nurse" && (
                  <>
                    <div className="col-6 profile-input-box">
                      <label className="form-label profile-label">
                        Qualification
                      </label>
                      <input
                        type="text"
                        placeholder="Enter your qualification"
                        name="qualification"
                        value={profileData.qualification.value}
                        onChange={handleChange}
                        className="form-control profile-input"
                      />
                      {profileData.qualification.showError && (
                        <span className="profile-input-error">
                          {profileData.qualification.errormsg}
                        </span>
                      )}
                    </div>

                    <div className="col-6 profile-input-box">
                      <label className="form-label profile-label">
                        Reg Number
                      </label>
                      <input
                        type="text"
                        placeholder="Enter your Reg Number"
                        name="rn"
                        value={profileData.rn.value}
                        onChange={handleChange}
                        className="form-control profile-input"
                      />
                      {profileData.rn.showError && (
                        <span className="profile-input-error">
                          {profileData.rn.errormsg}
                        </span>
                      )}
                    </div>
                  </>
                )}

                {profileData.type.value === "NursingStudent" && (
                  <>
                    <div className="col-6 signup-input-box">
                      <label className="form-label signup-label">Course</label>
                      <select
                        className="form-select signup-select"
                        name="course"
                        disabled
                        onChange={handleChange}
                      >
                        {(courseList || []).map((data: any) => (
                          <option
                            selected={data === profileData.course.value}
                            value={data}
                          >
                            {data}
                          </option>
                        ))}
                      </select>
                      {profileData.course.showError && (
                        <span className="signup-input-error">
                          {profileData.course.errormsg}
                        </span>
                      )}
                    </div>
                    <div className="col-6 signup-input-box">
                      <label className="form-label signup-label">
                        Semester
                      </label>
                      <select
                        className="form-select signup-select"
                        name="semester"
                        onChange={handleChange}
                      >
                        {(semesterList || []).map((data: any) => (
                          <option
                            selected={data.value === profileData.semester.value}
                            value={data.value}
                          >
                            {data.title}
                          </option>
                        ))}
                      </select>
                      {profileData.semester.showError && (
                        <span className="signup-input-error">
                          {profileData.semester.errormsg}
                        </span>
                      )}
                    </div>
                    <div className="col-6 signup-input-box">
                      <label className="form-label signup-label">College</label>
                      <input
                        type="text"
                        placeholder="Enter your collegeName"
                        name="college"
                        disabled
                        value={profileData.college.value}
                        className="form-control profile-input"
                      />
                      {/* <Typeahead
                        labelKey="collegeName"
                        className="CollegeTypeHead"
                        id="basic-typeahead-single"
                        onChange={handleCollageChange}
                        options={collegeList}
                        disabled
                        placeholder="Choose a College..."
                        selected={profileData.college.value}
                      /> */}
                      {profileData.college.showError && (
                        <span className="signup-input-error">
                          {profileData.college.errormsg}
                        </span>
                      )}
                    </div>
                  </>
                )}
                <div className="col-6"></div>
                <div className="btn-wrapper">
                  <button className="cancel-btn">Cancel</button>
                  <button className="signup-btn" onClick={handleUpdate}>
                    Update
                  </button>
                </div>
                {globalError.isError && (
                  <span className="signup-input-gloabl-error">
                    {globalError.errorMsg}
                  </span>
                )}
              </div>
            </div>
          </Tab>
          <Tab eventKey="myTest" title="My Test">
            {examPopData.map((data: any) => (
              <ExamNotifyProfile data={data} getQuestionList={takeNotifyTest} />
            ))}
            <div className="subjectFilterBox-wrapper">
              <div className="subjectFilterBox-wrapper-options">
                {
                  userData?.user?.role === "NursingStudent" ? (
                    <div className="subjectFilterBox">
                      <label className="form-label signup-label">
                        Filter Subject
                      </label>
                      <select
                        className="form-select signup-select"
                        name="course"
                        onChange={handleSubjectFilter}
                        value={selectedSubjectCode}
                      >
                        <option value={"Select"}>{"Select"}</option>
                        {(subjects || []).map((data: any) => (
                          <option value={data.subjectCode}>
                            {data.subjectTitle}
                          </option>
                        ))}
                      </select>

                      {/* <Typeahead
                        labelKey="subjectTitle"
                        className="SubjectFilterTypeHead"
                        id="basic-typeahead-subject"
                        onChange={handleSubjectFilter}
                        options={subjects}
                        ref={ref}
                        placeholder="Filter Subject..."
                      /> */}
                    </div>
                  ) : null

                  //   <div className="subjectFilterBox">
                  //   <label className="form-label signup-label">
                  //     Filter Module/Speciality
                  //   </label>
                  //   <Typeahead
                  //     labelKey="Module/Speciality"
                  //     className="SubjectFilterTypeHead"
                  //     id="basic-typeahead-subject"
                  //     onChange={handleModuleFilter}
                  //     options={subjects}
                  //     ref={ref}
                  //     placeholder="Filter Module/Speciality..."
                  //   />
                  // </div>
                }
                <div style={{ marginLeft: "18px" }}>
                  {userData?.user?.role === "NursingStudent" ? (
                    <select
                      className="form-select signup-select"
                      name="course"
                      onChange={handleTypeChange}
                      value={examTypeValue}
                    >
                      <option>{"All"}</option>
                      {examType.map((data: any) => (
                        <option>{data.value}</option>
                      ))}
                    </select>
                  ) : (
                    <select
                      className="form-select signup-select"
                      name="course"
                      onChange={handleTypeChange}
                      value={examTypeValue}
                    >
                      <option>{"All"}</option>
                      <option value="Mock">{"Mock"}</option>
                      <option value="Speciality">{"Speciality"}</option>
                      <option value="Module">{"Module Test"}</option>
                    </select>
                  )}
                </div>

                <span onClick={resetFilter}>Reset Filter</span>
              </div>

              <div className="selectedOptionWrapper">
                {selectedSubject && (
                  <div className="subjectTitle">
                    <span>Subject: </span> {selectedSubject}
                  </div>
                )}
                {examTypeValue && examTypeValue !== "All" && (
                  <div className="subjectTitle">
                    <span>Type of test: </span> {examTypeValue}
                  </div>
                )}
              </div>
            </div>
            {examData && examData?.length > 0 ? (
              <div className="subjectPurchased">
                {userData?.user?.role === "NursingStudent" ? (
                  <SubjectExamList
                    examData={examData}
                    isSubjectRequired={!selectedSubject}
                    isTypeRequired={!examTypeValue || examTypeValue === "All"}
                    getQuestionList={getQuestionList}
                  />
                ) : (
                  <ExamList
                    examData={examData}
                    getQuestionList={getQuestionData}
                  />
                )}
              </div>
            ) : (
              <div className="nodataFound">No Subject Purchased</div>
            )}
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default Profile;
